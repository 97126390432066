.document-teaser{
    padding: calc(20rem/16) calc(15rem/16);
    height: 100%;

    @media screen and (min-width: 768px){
        padding: calc(30rem/16) calc(15rem/16);
    }
}

.documents-teaser__img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.documents-teaser__img img{
    border: 1px solid #B3B3B3;
}

.document-teaser__body{
    @media screen and (min-width: 768px){
        padding-left: calc(20rem/16);
    }

}

.row--teaser-border > [class*='col-'] .teaser-border{
    border-bottom: 1px solid #B3B3B3;
}

.row--teaser-border > [class*='col-']:first-child .teaser-border{
    border-top: 1px solid #B3B3B3;
}

@media screen and (min-width: 768px){
    .row--teaser-border > [class*='col-']:nth-child(2) .teaser-border{
        border-top: 1px solid #B3B3B3;
    }

}