.dropdown--navbar-item .dropdown-toggle:after {
    display:none;
}
.dropdown--navbar-item .dropdown-menu {
    display:block;
    visibility:hidden;
    opacity:0;
    transition: visibility 0.001s, opacity 0.3s ease;

}
.dropdown--navbar-item:hover .dropdown-menu, .dropdown--navbar-item .dropdown-menu.show {
    display: block;
    visibility:visible;
    opacity:1;
    transition: visibility 0.3s 0.001s, opacity 0.3s ease;
}
.dropdown--navbar-item .dropdown-menu {
    border-radius:0;
    right: 0;
    left: auto;
    transform: none;
    border:1px solid var(--color-lightgrey);
    padding-top:0;
    margin-top:0;
}
.dropdown--navbar-item .dropdown-menu--cart {
    width: calc(340rem/16);
    padding-bottom:0;
    @media screen and (max-width: 767px) {
        width: calc(300rem/16);
        left:calc(-170rem/16);
        right:auto;

    }
}
.dropdown--navbar-item .dropdown-menu--user {
    width: calc(200rem/16);

}
.dropdown-menu__inner {
    padding: calc(16rem/16) calc(30rem/16);
    font-size: calc(15rem/16);
    line-height: calc(23/22);
}
.dropdown--navbar-item a:not(.btn):hover {
    color: var(--color-secondary);
}

.filter-dropdown-select .dropdown-toggle{
    font-family: var(--font-default);
    padding: calc(5rem/16) calc(10rem/16);
    font-size: calc(15rem/16);
}

.filter-dropdown-select .dropdown-toggle:after{
    content: var(--icon-arrow-right);
    font-family: iconfont;
    border: none;
    transform: rotate(90deg);
    font-size: calc(10rem/16);
    vertical-align: 0;
}


.filter-dropdown-select .dropdown-menu{
    min-width: auto;
}