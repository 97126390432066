.custom-radio-skewed:before,
.custom-radio-skewed:after {
    content: " ";
    display: table;
}.custom-radio-skewed:after {
     clear: both;
 }
.custom-radio-skewed__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio-skewed__box {
    position: relative;
    float: none;
    margin-right: .4375rem;
    background: transparent;
    width: auto;
    height: auto;
    margin-top: .1em;
    border-radius: 0;
    padding: 5px 15px;
    text-align: center;
    min-height: calc(50rem/16);
    display:inline-flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
}
.custom-radio-skewed__box:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    transform: skew(-20deg);
    border:1px solid var(--color-middlegrey);
    z-index: -1;
    top: 0;
    left: 0;
}
.custom-radio-skewed__input:checked ~ .custom-radio-skewed__box:before {
    background: var(--color-secondary);
}
.custom-radio-skewed__input:checked ~ .custom-radio-skewed__box {
    color:#fff;
}
.has-error .custom-radio-skewed__box {
    border-color: var(--color-danger)
}
.custom-radio-skewed__text {
    display: block;
    overflow: hidden;
    font-family: var(--font-default-bold);
    font-size: calc(15rem/16);
    line-height:1.2;
}
.custom-radio-skewed {
    margin-left: calc(10rem/16);
    min-height: calc(50rem/16);
    display: inline-flex;
    margin-bottom: calc(5rem/16);
}
.custom-radio-skewed label {
    cursor:pointer;
}
.custom-radio-skewed__additional-text {
    line-height:1.2;
    font-size: calc(15rem/16);
}
.custom-radio-skewed + .custom-radio-skewed {
    margin-left: 0;
}