.sidebar__item-header {
    border-radius: 0 1px 1px 0;
    min-height: calc(50rem/16);
    font-size: calc(15rem/16);
    line-height: calc(22/15);
    display:flex;
    align-items:center;
    padding: calc(14rem/16) calc(30rem/16) calc(14rem/16)  calc(40rem/16);
    margin-right: calc(30rem/16);
    font-family: var(--font-default);
    text-transform: uppercase;
    position:relative;
    overflow:hidden;
    @media screen and (max-width: 1199px) {
        margin-right: calc(10rem/16);
        padding-left: calc(15rem/16);
    }
}
.sidebar__item-header:before {
    content:'';
    background-color: var(--color-grey);
    position:absolute;
    transform: skew(-20deg);
    margin-left: calc(-20rem/16);
    width:100%;
    height:100%;
    top:0;
    left:0;
    z-index: -1;
    @media screen and (max-width: 1199px) {
        padding-right: calc(20rem/16);
    }
}

@media screen and (max-width: 767px) {
    .sidebar__item-header--left {
        margin-left:-15px;
    }
}
.sidebar__item-header--secondary,
.sidebar__item-header--secondary:hover,
.sidebar__item-header--secondary:focus {
    color:#fff;
}
.sidebar__item-header--secondary:before {
    background-color: var(--color-secondary);
}
.sidebar__item + .sidebar__item {
    padding-top: calc(10rem/16);
}
.sidebar__item-image {
    position: relative;
    display: inline-block;
    padding: calc(10rem/16);
    border-bottom: 1px solid var(--color-middlegrey);
    margin:0 auto 2rem auto;
}
.sidebar__item-image img {
    display: block;
    width: 100%;
    max-width: 100%;
    height:auto;
}
.sidebar__item-image .icon {
    position: absolute;
    right:1.5rem;
    bottom:1.5rem;
    color: #fff;
    font-size:1.375rem;
    text-shadow: 0px 0px 10px #000000;
    display: none;
}
.sidebar__item-image:hover .icon {
    display: block;
}
.sidebar__item-body {
    padding: calc(10rem/16) calc(50rem/16);
    @media screen and (max-width: 1199px) {
        padding: calc(10rem/16) calc(15rem/16);
    }
}
.sidebar__item-list__item {
    padding-bottom: calc(10rem/16);
}
.sidebar__item-list__item--active {
    font-family: var(--font-default-bold);
}
.sidebar__item-list__item-link {
    display: flex;
    flex-wrap: wrap;
    font-size: calc(15rem/16);
    line-height: calc(22/15);
    @media screen and (min-width: 768px) {
        padding-right: calc(20rem/16);
        position:relative;
    }
}

.sidebar-item__text {
    flex: 1;
}
.sidebar-item__icon {
    font-size: 9px;
    margin-top: 6px;
}
.sidebar__item-list__item-count {
    color: var(--color-middlegrey);
    padding-left: .5rem;
}
.sidebar__item-list__item-link .collapse-icon {
    @media screen and (min-width: 768px) {
        position: absolute;
        top: calc(7rem/16);
        right: calc(5rem/16);
    }
}
.sidebar__item-list__item-link:hover {
    color: var(--color-primary);
}
.sidebar__item-list__item-icon {
    color: var(--color-primary);
    font-size: calc(20rem/16);
}
.sidebar__item-list__additional-info {
    font-size: calc(12rem/16);
    margin-top: calc(4rem/16);
    line-height: calc(22/12);
    text-align:right;
    padding-right: calc(35rem/16);
    @media screen and (max-width: 767px) {
        text-align:left;
    }
}

.sidebar__contact-img-wrapper {
    padding-right: calc(63rem/16);
    @media screen and (max-width: 1199px) {
        padding-right: calc(43rem/16);
    }
}
.sidebar__contact-content-wrapper {
    padding: calc(18rem/16) calc(50rem/16) calc(18rem/16) calc(50rem/16);
    @media screen and (max-width: 1199px) {
        padding: calc(18rem/16) calc(10rem/16);
        position:relative;
    }
}
.sidebar__contact-person {
    z-index: 1;
    font-size: calc(15rem/16);
    position: relative;
    margin-bottom: calc(7rem/16);
}
.sidebar__item .collapse-icon {
    font-size: calc(10rem/16);
}
.sidebar__item-list-header {
    padding-bottom: calc(15rem/16);
}
.sidebar__item-list-header .icon {
    padding-left: calc(10rem/16);
}
.sidebar__item-list--has-header {
    padding-left: calc(20rem/16);
}
.sidebar__collapse-item-list {
    padding-top: calc(15rem/16);
}
.sidebar__item--has-header {
    margin-left: calc(20rem/16);
}