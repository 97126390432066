.img-teaser {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-secondary);
    cursor: pointer;
}
.img-teaser__img-container {
    flex: none;
    padding: .5rem;
    position: relative;
    overflow: hidden;
    border:1px solid var(--color-darkgrey);
}
.img-teaser__img {
    flex: none;
    margin: auto;
    display: block;
    max-width: 100%;
    height: auto;
}
.img-teaser__body {
    flex: auto;
    background: var(--color-secondary);
    color: #ffffff;
    padding: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: var(--font-default-bold);

    @media screen and (max-width: 767px) {
        font-size: .8em;
    }

    @media screen and (min-width: 768px) {
        min-height: 3.4rem;
    }

}
.img-teaser__img-gradient {
    position: absolute;
    height: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(229,229,229,0) 0%, rgba(229, 229, 229, 0.58) 100%);
    opacity: 1;
    transition: all 210ms ease-out;
    transform: translateY(0);
}
.img-teaser:hover .img-teaser__img-gradient {
    opacity: 0;
    transform: translateY(50%);
}
/* img-teaser--image-cover */
.img-teaser--image-cover {
    border: none;
}
.img-teaser--image-cover .img-teaser__img {
    width: 100%;
}
.img-teaser--image-cover .img-teaser__img-container {
    padding: 0;
}

.img-teaser__img-container .icon{
    font-size: calc(60rem/16);
}