.drawingBuffer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}

.barcode-overlay__video-container {
    position: relative;
}

.barcode-overlay__video-container,
.barcode-overlay__video-container video {
    width: 100%;
    height: 100%;
}