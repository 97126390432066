.quick-order-item {
    position: relative;
    border-bottom: 1px solid var(--color-middlegrey);
    @media screen and (max-width: 767px) {
        padding-top: calc(15rem/16);
        padding-bottom: calc(15rem/16);
    }
}
@media screen and (max-width: 767px) {
    .js-quick-order__item-group:first-child .quick-order-item{
        padding-top: 0;
    }
}
.quick-order-item__inner {
    padding-top: calc(10rem/16);
    padding-bottom: calc(10rem/16);
    @media screen and (max-width: 767px) {
        padding-top: calc(5rem/16);
        padding-bottom: calc(5rem/16);
    }
}
.quick-order-item__inner--spacing {
    padding-top: .75rem;
    @media screen and (max-width: 767px) {
        padding-top: calc(5rem/16);
    }
}
.quick-order-item__inner:empty {
    padding: 0;
}

@media (min-width: 768px) {
    .quick-order-item:hover {
        background-color:var(--color-lightgrey);
    }
}

.quick-order-item-grid__header {
    font-size:.875rem;
    border-bottom: 1px solid var(--color-middlegrey);
    border-top: 1px solid var(--color-middlegrey);
    font-family: var(--font-default-bold);
    background-color:var(--color-lightgrey);
}
.quick-order-item__error {
    font-size: calc(12rem/16);
    color: var(--color-danger);
    padding: calc(5rem/16) 0;
    text-align:right;
    max-width: calc(420rem/16);
    @media screen and (max-width: 767px) {
        text-align:left;
    }
}
.quick-order-item__update-status {
    position:relative;
}
/*.quick-order-item .status-pending:not(.has-success):not(.has-error):before {
    visibility: hidden;
}*/
.quick-order-item input[type=number]::-webkit-inner-spin-button,
.quick-order-item input[type=number]::-webkit-outer-spin-button
{
    -webkit-appearance: none;
    margin: 0;
}
.quick-order-item__update-status.status-error .form-control {
    border-bottom: 2px solid var(--color-danger);
}

/* textarea */
.quick-order-item .quick-order-item__update-status--textarea:before {
    content: none;
}