.text-with-medium-slider__wrapper {
    position: relative;
    padding-top:50px;
}
@media screen and (max-width: 767px) {
    .text-with-medium-slider__controls {
        position: absolute;
        top:0;
        left:-20px;
        right:-20px;
    }
}