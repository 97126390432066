:root {
    --navbar-height: calc(133rem/16);
    --navbar-height-mobile: calc(96rem/16);
}

html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    body {
        width: calc(982rem/16);
    }
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
.container.container {
    max-width: calc(1320rem/16);
    width: 100%;
    @media screen and (max-width: 767px) {
        padding-left:calc(20rem/16);
        padding-right:calc(20rem/16);
    }
}

.container-narrow{
    max-width: calc(880rem/16);
    width: 100%;
    margin: 0 auto;
    @media screen and (max-width: 767px) {
        padding-left:calc(20rem/16);
        padding-right:calc(20rem/16);
    }
}

@media screen and (max-width: 767px) {
    .container.container--no-padding-xs {
        padding: 0;
        overflow: hidden;
    }
}

.page-wrapper {
    padding-top: calc(147rem / 16);
    @media screen and (max-width: 767px) {
        padding-top: calc(110rem/16);
    }
}

.main-content-body--full-height {
    @media screen and (min-width: 768px) {
        min-height: calc(100vh - 204px);
    }
}

.container-has-sidebar {
    @media screen and (min-width:768px) {
        display: grid;
        grid-template-columns: minmax(200px, 310px) minmax(742px, 1330px);
    }
    @media screen and (min-width: 2231px) {
        justify-content: center;
    }
}
.container-has-sidebar .container-has-sidebar__inner{
    max-width: calc(1330rem/16);
    padding-left: calc(15rem/16);
    padding-right: calc(15rem/16);
    width:100%;
}

// IE fix
.container-has-sidebar > div:nth-child(1) { -ms-grid-column: 1; }
.container-has-sidebar > div:nth-child(2) { -ms-grid-column: 2; }



#main{
    @media screen and (min-width: 768px){
        min-height: calc(100vh - 147px - 51px); /*in case the page does not contain enough content */
        overflow: hidden;
    }
}

.has-anchor-offset {
    border-top: 1px solid transparent;
    margin-top: calc(var(--navbar-height) * -1);
    padding-top: var(--navbar-height);
    @media (max-width: 767px) {
        margin-top: calc(var(--navbar-height-mobile) * -1);
        padding-top: var(--navbar-height-mobile);
    }
}