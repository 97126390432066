.returns-portal-item-grid  {
    display: grid;
    grid-template-columns: minmax(120px,12%) minmax(120px,12%) minmax(155px,20%) minmax(155px,20%) minmax(130px,13%) minmax(170px,17%) minmax(70px, 8%);
    -ms-grid-columns: minmax(120px,12%) minmax(120px,12%) minmax(155px,20%) minmax(155px,20%) minmax(130px,13%) minmax(170px,17%) minmax(70px, 8%);
    @media screen and (max-width: 767px) {
        grid-template-columns: 100%;
        align-items: center;
        margin-left: calc(-5rem/16);
        margin-right: calc(-5rem/16);
    }
}
.returns-portal-item-grid > .returns-portal-item-grid__col:nth-child(1) {
    -ms-grid-column: 1;
}
.returns-portal-item-grid > .returns-portal-item-grid__col:nth-child(2) {
    -ms-grid-column: 2;
}
.returns-portal-item-grid > .returns-portal-item-grid__col:nth-child(3) {
    -ms-grid-column: 3;
}
.returns-portal-item-grid > .returns-portal-item-grid__col:nth-child(4) {
    -ms-grid-column: 4;
}
.returns-portal-item-grid > .returns-portal-item-grid__col:nth-child(5) {
    -ms-grid-column: 5;
}
.returns-portal-item-grid > .returns-portal-item-grid__col:nth-child(6) {
    -ms-grid-column: 6;
}
.returns-portal-item-grid > .returns-portal-item-grid__col:nth-child(7) {
    -ms-grid-column: 7;
}
.returns-portal-item-grid > .returns-portal-item-grid__col:nth-child(8) {
    -ms-grid-column: 8;
}
.returns-portal-item-grid > .returns-portal-item-grid__col:nth-child(9) {
    -ms-grid-column: 9;
}
.returns-portal-item-grid > .returns-portal-item-grid__col:nth-child(10) {
    -ms-grid-column: 10;
}
.returns-portal-item-grid > .returns-portal-item-grid__col:nth-child(11) {
    -ms-grid-column: 11;
}
.returns-portal-item-grid__col,
.returns-portal-item-grid-error__col {
    padding-left: calc(10rem/16);
    padding-right: calc(10rem/16);
    @media screen and (max-width: 1199px) {
        padding-left: calc(5rem/16);
        padding-right: calc(5rem/16);
    }
}
.returns-portal-item-grid__col--1-span7-md {
    @media screen and (min-width: 767px) {
        grid-column: 1 / span 6;
    }
}
.returns-portal-item-grid__col--9-span2-md {
    @media screen and (min-width: 767px) {
        grid-column: 9 / span 2;
    }
}
.returns-portal-item-grid-error .returns-portal-item-grid-error__col:first-of-type, .returns-portal-item-grid-error .returns-portal-item-grid-error__col:nth-of-type(3){
    grid-column: 3/span 3;
    @media screen and (max-width: 767px) {
        grid-column: 1 / span 2;
    }
}
.returns-portal-item-grid-error .returns-portal-item-grid-error__col:nth-of-type(2){
    grid-column: 7/ span 3;
    @media screen and (max-width: 767px) {
        grid-column: 1 / span 2;
    }
}
.returns-portal-item-grid__col--two-cols-xs {
    @media screen and (max-width: 767px) {
        grid-column: 1 / span 2;
    }
}
.returns-portal-grid-item__col--right-xs {
    @media screen and (max-width: 767px) {
        grid-column: 2 / -1;
    }
}
.returns-portal-grid-item__col--left-xs {
    @media screen and (max-width: 767px) {
        grid-column: 1 / -1;
    }
}

.returns-portal-grid-item__col--full-width-xs {
    @media screen and (max-width: 767px) {
        grid-column: 1 / span 3;
    }
}

.returns-portal-grid-item__col--span-2-xs {
    @media screen and (max-width: 767px) {
        grid-column-end: span 2;
    }
}