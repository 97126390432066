.order-history-header {
    padding: 1rem;
    font-size:.875rem;
    font-family: var(--font-default-bold);
    background-color:var(--color-lightgrey);
    border-bottom: 1px solid var(--color-middlegrey);
    border-top: 1px solid var(--color-middlegrey);

    @media screen and (max-width: 767px) {
        display: none;
    }
}

.order-history-item {
    padding: 1rem .5rem;
    position:relative;
    border-bottom: 1px solid var(--color-middlegrey);

    @media screen and (min-width: 768px) {
        padding: 1rem;
    }
}
.order-history-item + .order-history-item {
    border-top: none;
}

.order-history-item__downloads {
    display: inline-block;
    text-align:center;
    text-decoration: none;
    color: var(--color-secondary);
    font-size: 1rem;
    -webkit-appearance: none;
    padding: 0;
    border:0;
    background: none;
}
.order-history-item__downloads > .icon {
    font-size: 1.375rem;
    position: relative;
    vertical-align: -.2em;
    padding:.5rem 0;
    margin:.5rem 0;
    @media screen and (min-width: 768px) {
        font-size: 1.5rem;
        vertical-align: .2em;
        margin:0;
        padding:0;
    }
}
.order-history-item__downloads:hover,
.order-history-item__downloads:focus {
    color: var(--color-primary);
}



.order-downloads {
    display: inline-block;
    text-align:center;
    text-decoration: none;
    font-size:.75rem;
    color: var(--color-secondary);
    margin:.25rem .5rem .25rem 0;
}
.order-downloads:hover,
.order-downloads:focus {
    text-decoration: none;
    color: var(--color-primary);
}
.order-downloads .icon {
    display: block;
    font-size:1.25rem;
    line-height:1;
}