.returns-overview-grid__header {
    font-size:.875rem;
    border-top: 1px solid var(--color-middlegrey);
    font-family: var(--font-default-bold);
    background-color:var(--color-lightgrey);
}

.returns-overview-grid__row  {
    @media screen and (min-width: 768px){
        display: grid;
        grid-template-columns: minmax(80px,7%) 15% 15% minmax(90px,9%) 6% 14% 14% minmax(50px,8%) 6% 6%;
        -ms-grid-columns: 7% 15% 15% 9% 6% 14% 14% 8% 6% 6%;
    }

    border-bottom: 1px solid #B3B3B3;

    @media screen and (max-width: 767px) {
        padding: calc(10rem/16) 0;
    }
}
.returns-overview-grid__row  > .returns-overview-grid__col:nth-child(1) {
    -ms-grid-column: 1;
}
.returns-overview-grid__row  > .returns-overview-grid__col:nth-child(2) {
    -ms-grid-column: 2;
}
.returns-overview-grid__row  > .returns-overview-grid__col:nth-child(3) {
    -ms-grid-column: 3;
}
.returns-overview-grid__row  > .returns-overview-grid__col:nth-child(4) {
    -ms-grid-column: 4;
}
.returns-overview-grid__row  > .returns-overview-grid__col:nth-child(5) {
    -ms-grid-column: 5;
}
.returns-overview-grid__row  > .returns-overview-grid__col:nth-child(6) {
    -ms-grid-column: 6;
}
.returns-overview-grid__row  > .returns-overview-grid__col:nth-child(7) {
    -ms-grid-column: 7;
}
.returns-overview-grid__row  > .returns-overview-grid__col:nth-child(8) {
    -ms-grid-column: 8;
}
.returns-overview-grid__row  > .returns-overview-grid__col:nth-child(9) {
    -ms-grid-column: 9;
}
.returns-overview-grid__row  > .returns-overview-grid__col:nth-child(10) {
    -ms-grid-column: 10;
}

.returns-overview-grid__body .returns-overview-grid__row:hover{
    background-color: var(--color-lightgrey);
}

.returns-overview-grid__col{
    padding: calc(21rem/16) calc(8rem/16);
    display: flex;

    @media screen and (min-width: 768px){
        justify-content: center;
        flex-direction: column;
    }

    @media screen and (max-width: 767px){
        padding: 0.125rem 0.5rem;
        justify-content: space-between;
    }
}