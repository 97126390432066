.cart-dropdown__content {
    flex: auto;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    max-height: calc(550rem/16);
    padding-left: calc(20rem/16);
    padding-right: calc(20rem/16);

    @media screen and (max-width: 767px) {
        max-height: calc(250rem/16);
        padding: calc(0rem/16) calc(10rem/16);
    }
}
.cart-dropdown__footer {
    flex: none;
    border: 1px solid #E5E5E5;
    box-shadow: 0 -1px 3px 0 rgba(0,0,0,0.1);
    padding: calc(20rem/16) calc(30rem/16);
    font-size: calc(15rem/16);

    @media screen and (max-width: 767px) {
        padding: calc(20rem/16) calc(10rem/16);
    }
}