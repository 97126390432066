.wysiwyg {
    font-size: calc(15rem/16);
}
.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 2em;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-primary);
}
.wysiwyg a:hover {
    text-decoration: underline;
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: var(--color-primary);
    text-decoration: underline;
}
.wysiwyg ul li:before, .list li:before {
    content: var(--icon-bulletpoint);
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0);
    color: #eb7805;
    font-size: calc(17rem/16);
    position:absolute;
    top: calc(2rem/16);
    left:0;
    @media screen and (max-width: 767px) {
        font-size:calc(14rem/16);
    }
}
.wysiwyg ul>li,  .list li {
    padding-left: calc(30rem/16);
    text-indent:0;
    position:relative;
    padding-bottom: calc(15rem/16);
    line-height: calc(22/15);
    @media screen and (max-width: 767px) {
        padding-left: calc(20rem/16);
    }
}
.wysiwyg ul, list {
    list-style: none;
    padding: 0;
    margin: 0;
}