.returns-grid__header{
    font-size:.875rem;
    border-top: 1px solid var(--color-middlegrey);
    font-family: var(--font-default-bold);
    background-color:var(--color-lightgrey);
}

.returns-grid__row  {
    @media screen and (min-width: 768px){
        display: grid;
        grid-template-columns: minmax(65px,7%) minmax(70px,7%) minmax(90px,8%) minmax(55px,10%) 14% 16% minmax(85px,7%) 6% 5% 14% minmax(60px,4%);
        -ms-grid-columns: 6% 7% 8% 8% 15% 19% 7% 5% 5% 15% 5%;
    }

    border-bottom: 1px solid #B3B3B3;

    @media screen and (max-width: 767px) {
        padding: calc(10rem/16) 0;
    }
    @media (min-width: 1200px) {
        grid-template-columns: minmax(65px,7%) minmax(70px,7%) minmax(90px,8%) minmax(75px,10%) 15% 18% minmax(85px,7%) 6% 5% 14% minmax(60px,4%);
    }
}

.returns-grid__row  > .returns-grid__col:nth-child(1) {
    -ms-grid-column: 1;
}
.returns-grid__row  > .returns-grid__col:nth-child(2){
    -ms-grid-column: 2;
}
.returns-grid__row  > .returns-grid__col:nth-child(3) {
    -ms-grid-column: 3;
}
.returns-grid__row  > .returns-grid__col:nth-child(4) {
    -ms-grid-column: 4;
}
.returns-grid__row  > .returns-grid__col:nth-child(5) {
    -ms-grid-column: 5;
}
.returns-grid__row  > .returns-grid__col:nth-child(6)  {
    -ms-grid-column: 6;
}
.returns-grid__row  > .returns-grid__col:nth-child(7) {
    -ms-grid-column: 7;
}
.returns-grid__row  > .returns-grid__col:nth-child(8) {
    -ms-grid-column: 8;
}
.returns-grid__row  > .returns-grid__col:nth-child(9) {
    -ms-grid-column: 9;
}
.returns-grid__row  > .returns-grid__col:nth-child(10) {
    -ms-grid-column: 10;
}

.returns-grid__row  > .returns-grid__col:nth-child(11) {
    -ms-grid-column: 11;
}

.returns-grid__body .returns-grid__row:hover{
    background-color: var(--color-lightgrey);
}

.returns-grid__col{
    padding: calc(21rem/16) calc(4rem/16);
    display: flex;

    @media screen and (min-width: 768px){
        justify-content: center;
        flex-direction: column;
    }

    @media (min-width: 1200px) {
        padding: calc(21rem/16) calc(8rem/16);
    }

    @media screen and (max-width: 767px){
        padding: 0.125rem 0.5rem;
        justify-content: space-between;
    }
}

.returns-grid__col.returns-grid__col--quantity-error {
    padding-top: 0;
    padding-bottom: calc(4rem/16);
    @media (min-width: 768px) {
        -ms-grid-row: 2;
        grid-column: 7/span 3;
    }
}

.returns-grid__btn {
    font-size:1.25rem;
}

@media (max-width: 767px) {
    .returns-grid__btn {
        font-size:.875rem;
    }
    .returns-grid__btn .icon {
        font-size:1.125rem;
        position: relative;
        top:.125rem;
    }
}

