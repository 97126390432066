.thumb-slider__thumbs-wrapper {
    position:relative;
}
.thumb-slider__thumbs .slick-slide > div {
    display:flex;
}
.thumb-slider__thumbs {
    position: relative;
}
.thumb-slider__thumb {
    position:relative;
    border: 1px solid var(--color-darkgrey);
    cursor:pointer;
}
.thumb-slider__thumbs .slick-slide {
    margin-right: calc(10rem/16);
}
.thumb-slider__thumb:before {
    content:'';
    display: block;
    position: absolute;
    height: 2px;
    background: var(--color-secondary);
    bottom: 0;
    width: 100%;
    opacity:0;
    transition: all 0.3s ease;
}
.slick-active.slick-current .thumb-slider__thumb:before, .thumb-slider__thumb:hover:before {
    opacity:1;
}
.thumb-slider__main-item-video:after {
    content:var(--icon-play);
    position: absolute;
    height:100%;
    width:100%;
    font-family: 'iconfont';
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(70rem/16);
    text-shadow: 0 0 calc(20rem/16) #fff;
    color: var(--color-primary);
}
.thumb-slider__thumb-video:after {
    content:var(--icon-play);
    position: absolute;
    height:100%;
    width:100%;
    font-family: 'iconfont';
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: calc(35rem/16);
    text-shadow: 0 0 calc(20rem/16) #fff;
    color: var(--color-primary);

}
.thumb-slider__fullscreen-btn {
    opacity:0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: var(--color-secondary);
    transition: opacity 0.3s ease;
    z-index: 2;
}
.thumb-slider__main-item:hover .thumb-slider__fullscreen-btn {
    opacity:1;
    color: var(--color-secondary);
}
.thumb-slider__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    padding: calc(10rem/16);
    line-height: 1;
    opacity:1;
    transition: opacity 0.2s ease-in-out;
}
.thumb-slider__arrow.slick-disabled {
    pointer-events:none;
    opacity:0;
}
.thumb-slider__arrow.arrow-next {
    position:absolute;
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 82%,rgba(255,255,255,1) 100%);
    height:100%;
    width: calc(50rem/16);
    right:0;
    z-index: 1;
    text-align:right;
}

.thumb-slider__arrow.arrow-prev {
    left: calc(-2rem/16);
    position:absolute;
    background: linear-gradient(to left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 82%,rgba(255,255,255,1) 100%);
    height:100%;
    width: calc(50rem/16);
    z-index: 1;
    text-align:left;
}
.thumb-slider__arrow:hover {
    animation: arrowAnimation 1.1s forwards infinite;
}
.thumb-slider__main {
    padding-bottom: calc(24rem/16);
    @media screen and (max-width: 767px) {
        padding-bottom: calc(15rem/16);
    }
}
@keyframes arrowAnimation {
    0% {
        transform:translateX(0px) translateY(-50%);
        color: var(--color-text-default);
    }
    60% {
        transform:translateX(2px) translateY(-50%);
        color: var(--color-primary);
    }
    100% {
        transform:translateX(0px) translateY(-50%);
        color: var(--color-text-default);
    }
}
