.cart-item {
    position: relative;
    border-bottom: 1px solid var(--color-middlegrey);
    @media screen and (max-width: 767px) {
        padding-top: calc(15rem/16);
        padding-bottom: calc(15rem/16);
    }
}
.cart-item__inner {
    padding-top: calc(10rem/16);
    padding-bottom: calc(10rem/16);
    @media screen and (max-width: 767px) {
        padding-top: calc(2rem/16);
        padding-bottom: calc(4rem/16);
    }
}
.cart-item__inner--spacing {
    padding-top: .75rem;
    @media screen and (max-width: 767px) {
        padding-top: calc(5rem/16);
    }
}
.cart-item:hover {
    background-color:var(--color-lightgrey);
}
.cart-item-grid__header {
    font-size:.875rem;
    font-family: var(--font-default-bold);
    background-color:var(--color-lightgrey);
    border-top: 1px solid var(--color-middlegrey);
    border-bottom: 1px solid var(--color-middlegrey);
}
.cart-item__error {
    font-size: calc(12rem/16);
    color: var(--color-danger);
    padding: calc(5rem/16) 0;
    text-align:right;
    max-width: calc(420rem/16);
    @media screen and (max-width: 767px) {
        text-align:left;
    }
}
.cart-item__update-status {
    position:relative;
}
.cart-item__inner input[type=number]::-webkit-inner-spin-button,
.cart-item__inner input[type=number]::-webkit-outer-spin-button
{
    -webkit-appearance: none;
    margin: 0;
}
.cart-item__update-status.status-error .form-control {
    border-bottom: 2px solid var(--color-danger);
}

/* textarea */
.cart-item .cart-item__update-status--textarea:before {
    content: none;
}