.custom-alert {
    background-color:#fff;
    border-left: 1px solid #B3B3B3;
    border-right: 1px solid #B3B3B3;
    border-top: 1px solid #B3B3B3;
    border-bottom-width: 3px;
    padding-left: calc(35rem/16);
    padding-top: calc(14rem/16);
    padding-bottom: calc(14rem/16);
    font-size: calc(14rem/16);
}
.custom-alert.alert-warning {
    color: var(--color-warning);
    border-bottom: 2px solid var(--color-warning);
}
.custom-alert:before {
    content:var(--icon-elements);
    font-family: iconfont;
    position: absolute;
    left:calc(10rem/16);
    display:block;
    width: calc(20rem/16);
    height: calc(20rem/16);
}
.custom-alert.alert-warning:before {
    content:var(--icon-elements);
}
.custom-alert.alert-success {
    color: var(--color-success);
    border-bottom: 2px solid var(--color-success);
}
.custom-alert.alert-success:before {
    content:var(--icon-elements);
}
.custom-alert.alert-danger {
    color: var(--color-danger);
    border-bottom: 2px solid var(--color-danger);
}
.custom-alert.alert-danger:before {
    content:var(--icon-elements);
}
.custom-alert.alert-dismissible .close {
    color: #000;
    opacity:1;
}
.custom-alert-box:before {
    display:none;
}
.custom-alert-box {
    border-top:none;
    border-left:none;
    border-right:none;
    font-size: calc(15rem/16);
    line-height: calc(23/15);
}