.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #8d8d8d;
    opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #8d8d8d;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
    color: #8d8d8d;
}

input[type=number] {
    -moz-appearance:textfield;
}

/* make textarea in Firefox the same height as in other browsers (removes place for horizontal scrollbar) */
textarea.form-control {
    overflow-x: hidden;
}

.input-with-icon {
    position: relative;
}

.input-with-icon__icon {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    padding-left: calc(16rem/16);
    pointer-events: none;
}

.input-with-icon__input {
    padding-left: calc(40rem/16);
}

.form-group-with-button {
    position: relative;
}

.form-group-with-button__button {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;

    display: flex;
    align-items: center;
    padding: 0 1rem;
    color: var(--color-middlegrey);
    font-size: 1rem;
}

.form-group--has-clear-button {
    position: relative;
}

.form-group--has-clear-button .form-control {
    padding-right: calc(24rem/16);
}

.form-group__clear-button {
    position:absolute;
    right: 0;
    line-height: 1;
    top: 0;
    bottom: 0;
    padding: 0 .75rem;
    color: var(--color-middlegrey);
    font-size: calc(10rem/16);
}