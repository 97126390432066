.twitter-typeahead {
    width: 100%;
    display: block !important;
}
.tt-menu {
    padding: 5px 0;
    right: 0;
    background: #ffffff;
    margin-bottom: 10px;
    max-height: 600px;
    overflow-y: auto;
    border: 1px solid var(--color-grey);
}

@media screen and (max-width: 767px) {
    .tt-menu {
        max-height: 900px;
    }
}
.tt-suggestion.tt-selectable,
.tt-dataset-heading,
.tt-empty-message {
    padding: 6px 0;
}
.tt-suggestion.tt-selectable:hover {
    cursor: pointer;
    background-color: var(--color-grey);
}
.tt-cursor .card, .tt-cursor {
    border: 1px solid var(--color-primary);
    margin: -1px;
}
.tt-hint {
    color: #999
}
.tt-suggestion a.tt-suggestion__cart,
.tt-suggestion a.tt-suggestion__wishlist {
    display: inline-block;
}

/* ctas */
.tt-dataset-products--has-cta .tt-suggestion.tt-suggestion {
    display: block;
    position: relative;
    padding-right:4.75rem;
}
.tt-suggestion__ctas {
    display: none;
}
.tt-dataset-products--has-cta .tt-suggestion__ctas {
    display: block;
    white-space: nowrap;
    position: absolute;
    right:0;
    top:.5rem;
    z-index:500;
}
.tt-dataset-products--has-cta .tt-suggestion .tt-suggestion__cart,
.tt-dataset-products--has-cta .tt-suggestion .tt-suggestion__wishlist {
    padding:0 .5rem;
    color:var(--color-secondary);
    font-size:1.125rem;
    line-height:1;
    display: inline-block;
    position: relative;
}


.tt-dataset-heading {
    position: relative;
    padding: 5px 0 5px 25px;
    margin-bottom: 5px;
    border-bottom: 1px solid var(--color-middlegrey);
}
.tt-dataset-heading-icon {
    position: absolute;
    top: 45%;
    left: 0;
    transform: translateY(-50%);
}
.navbar__search .tt-menu {
    width: 100%;
    padding: calc(20rem / 16);
    z-index: 999 !important;
    right: 0;
    @media screen and (max-width: 767px) {
        width: calc(100% + 4.375rem);
        border: 0;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
    @media screen and (min-width: 768px) {
        margin-left: -20px;
    }
}
.tt-menu .mark, .tt-menu mark {
    background-color:transparent;
    font-family: var(--font-default-bold);
    padding:0;
}
.tt-dataset-heading-link {
    color: var(--color-secondary);
    float: right;

    @media screen and (max-width: 1199px) {
        float: none;
        display: block;
    }
}

/* typeahead in product grid sidebar (e.g. brand filter) */
.filter-typeahead .tt-menu {
    max-height: calc(400rem/16);
    padding: 1px; /* needed for the .tt-cursor border */
}

.filter-typeahead .tt-suggestion {
    padding: calc(6rem/16);
}

.filter-typeahead .tt-no-results {
    padding: calc(6rem/16);
}