.teaser-slider__dots-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0;
}
.teaser-slider__dots {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0;
}
.teaser-slider__dots button {
    color: #fff;
    font-size: calc(10rem/16);
    line-height:1;
    width: calc(18rem/16);
    height: calc(18rem/16);
    margin: 0 2px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:0;
}
.teaser-slider__dots button:before {
    display:block;
    border-radius:50%;
    box-shadow:none;
    content:'';
    background: var(--color-secondary);
    height:calc(18rem/16);
    width:calc(18rem/16);
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index: -1;
}
.teaser-slider__dots li:not(.slick-active) button:after {
    border-radius:50%;
    box-shadow:none;
    content:'';
    background: #fff;
    width:calc(18rem/16);
    height:calc(18rem/16);
    border: 1px solid var(--color-secondary);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    transition: all 0.2s ease;
    z-index: -1;
}
.teaser-slider__dots li:not(.slick-active) button {
    color: var(--color-secondary);
}