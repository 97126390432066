.glossary-nav__title {
    font-size: 2rem;
    margin-bottom: 0;
}
.glossary-nav__content {
    margin-bottom: 0;
}
.glossary-nav__list {
    column-count: 2;
    column-gap: calc(20rem / 16);

    @media screen and (min-width: 768px) {
        column-count: 3;
        padding-top: .5rem;
    }
    @media screen and (min-width: 1000px) {
        column-count: 4;
    }
    @media screen and (min-width: 1200px) {
        column-count: 5;
    }
}
.glossary-nav__link {
    display: inline-block;
    padding: .5rem;
}
.glossary-nav__link:hover {
    text-decoration: underline;
}
.glossary-nav__item {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #B3B3B3;
}
