.wishlist__btn.is-added .wishlist__btn-text {
    position:relative;
}
.wishlist__btn.is-added .wishlist__btn-text:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--color-secondary);
    height: 1px;
}
.is-added .icon-heart-outline:before,
.wishlist__btn.is-added .wishlist__btn-icon:before{
    content: var(--icon-heart-filled);
} 