.navbar-wrapper {
    position:absolute;
    width: 100%;
    z-index: 999;
}
.navbar-wrapper.not-is-affix {
    opacity:0;
    transform:translateY(-100%);
    position:absolute;
    transition:opacity 0.2s ease, transform 0.2s ease;
    overflow:hidden;
}
.navbar-wrapper.is-affix {
    position:fixed;
    opacity:1;
    transform:translateY(0%);
    transition:opacity 0.2s ease, transform 0.2s ease;
    width:100%;
    z-index: 999;
}
.navbar-main {
    background-color: var(--color-primary);
    height: calc(55rem/16);
    padding: 0 0 0 calc(30rem/16);
    overflow:hidden;
    @media screen and (max-width: 767px) {
        height: calc(45rem/16);
        padding-left: 0;
    }
}
.navbar-nav {
    height:100%;
    display:flex;
    align-items:center;
}
.nav-item {
    font-size: calc(18rem/16);
    line-height: calc(24/18);
    font-family: var(--font-headline);
    color: var(--color-lightgrey);
    text-transform: uppercase;
    position:relative;
    height:100%;
    @media screen and (max-width: 1399px) {
        font-size: calc(16rem/16);
    }
}
.nav-item:before {
    background-color: var(--color-secondary);
    content:'';
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    opacity:0;
    transform:skew(-20deg);
    transition: opacity 0.2s ease;
}
.nav-item__link {
    padding-left: calc(20rem/16);
    padding-right: calc(20rem/16);
    z-index: 1;
    position:relative;
    display: flex;
    align-items: center;
    height: 100%;
    @media screen and (max-width: 1399px) {
        padding-left: calc(10rem/16);
        padding-right: calc(10rem/16);
    }
}
.nav-item:hover:before {
    opacity:1;
}
.nav-item.active:before {
    opacity:1;
}
.navbar-brand, .navbar-brand img {
    height: calc(55rem/16);
    padding:0;
    margin-right:0;
    position: relative;
    @media screen and (max-width: 767px) {
        height: calc(45rem/16);
    }
}
.navbar-brand{
    @media screen and (max-width: 1199px) {
        display:flex;
        align-items:center;
    }
}
.navbar-brand img{
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        height: calc(30rem/16);
    }
}
.navbar-brand:before {
    @media screen and (min-width: 1200px){
        content:'';
        display:block;
        position:absolute;
        width: calc(100% + 6px);
        right: -10px;
        height: 100%;
        background-color:#fff;
        transform: skew(-20deg);
        z-index: 0;
    }
    @media screen and (max-width: 767px){
        content:'';
        display:block;
        position:absolute;
        width: calc(100% + 6px);
        right: -10px;
        height: 100%;
        background-color:#fff;
        transform: skew(-20deg);
        z-index: 0;
    }
}
.navbar__menu-button {
    color:#fff;
}