.compare-list {
    font-size: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        padding-right: calc(50rem / 16);
    }
    @media screen and (min-width: 1200px) {
        font-size: calc(15rem / 16);
    }
    @media screen and (min-width: 1400px) {
        padding-right: 0;
    }
}
.compare-list__item {
    transition: transform .2s;
    cursor: grab;
}

.modal-body .compare-list__item {
    min-width:calc(325rem / 16);
}

@media screen and (min-width: 768px) {
    .compare-list__item:hover,
    .compare-list__item:hover .is-affix.compare-list__item__head-affix {
        background: var(--color-lightgrey);
    }
}
.compare-list__item__head {
    padding: calc(15rem / 16) calc(25rem / 16);
    position: relative;

    @media screen and (min-width: 768px) {
        padding: calc(15rem / 16);
    }
}
.is-affix.compare-list__item__head-affix {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 1;
    padding: calc(5rem / 16) calc(10rem / 16);
    box-shadow: calc(5rem / 16) 0 calc(5rem / 16) rgba(0,0,0,.2);
    will-change: transform;

    @media screen and (min-width: 1200px) {
        padding: calc(5rem / 16) calc(15rem / 16);
    }
}
.visible-affix,
.is-affix .hide-affix {
    display: none;
}
.is-affix .visible-affix {
    display: block;
}
.compare-list__item__head__img {
    padding: calc(15rem / 16) 0;
    margin: calc(10rem / 16) calc(-15rem / 16) calc(14rem / 16);
    position: relative;
    height: calc(130rem / 16);
}
.compare-list__item__head__img img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
}
.compare-list__item__badge {
    display: inline-block;
    padding: calc(5rem / 16) calc(12rem / 16);
}
.compare-list__item__col {
    padding: calc(7rem / 16) calc(25rem / 16) calc(5rem / 16);
    border-top: calc(1rem / 16) solid var(--color-middlegrey);

    @media screen and (min-width: 768px) {
        padding: calc(7rem / 16) calc(15rem / 16) calc(5rem / 16);
    }
}
.compare-list__item__col.compare-list__item__col--tooltip {
    position: relative;
    padding-right:3rem;
}
.compare-list .is-not-equal {
    background: lightyellow;
}
.compare-list .is-equal {
    background: #e8ffe8;
}


@media screen and (min-width: 768px) {
    .compare-list .slick-track {
        margin-left: 0;
    }
    .compare-list .slick-slider .slick-list {
        padding-right: calc(46rem / 16);
    }
    .compare-list .slick-slide {
        transition: opacity 200ms ease-in-out;
        opacity: .3;
    }
    .compare-list .slick-slide.slick-active,
    .compare-list .slick-slide.is-selected {
        opacity: 1;
    }
    .compare-list .slick-slide.is-ghost:not(.sortable-fallback) {
        visibility: hidden;
    }
}
.compare-list .slick-arrow {
    font-size: calc(20rem / 16);
    line-height: 1;
    transition: opacity 200ms ease-in-out;
    padding: 0 calc(8rem / 16);

    @media screen and (min-width: 768px) {
        position: absolute;
        z-index: 1;
        top: 20%;
        left: calc(-50rem / 16);
    }
}
.compare-list .slick-arrow.slick-next {
    @media screen and (min-width: 768px) {
        left: auto;
        right: calc(-50rem / 16);
    }
}
.compare-list .slick-arrow:hover {
    outline: none;
    color: var(--color-primary);
}
.compare-list .slick-arrow.slick-disabled {
    opacity: .5;

    @media screen and (min-width: 768px) {
        opacity: 0;
        pointer-events: none;
    }
}

.compare-list__nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    padding: calc(10rem / 16) calc(20rem / 16);
    z-index: 9;
    box-shadow: 0 0 calc(5rem / 16) rgba(0,0,0,.2);
}