.main-nav-overlay__content {
    margin-top: calc(20rem/16);
}
.main-nav-overlay__item  {
    margin-bottom: calc(40rem/16);
}
.main-nav-overlay__item-title {
    font-size: calc(18rem/16);
    line-height: calc(24/18);
    font-family: var(--font-headline);
    text-transform:uppercase;
}
.main-nav-overlay.is-open {
    top: calc(96rem/16);
}