.radio-bar {
    border-top: calc(1rem / 16) solid var(--color-middlegrey);
    cursor: pointer;
    font-size: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(15rem / 16);
    }
}
.radio-bar__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.radio-bar label {
    display: block;
    width: 100%;
}
.radio-bar__content__box {
    min-width: calc(14rem / 16);
    width: calc(14rem / 16);
    height: calc(14rem / 16);
    border-radius: 50%;
    border: calc(1rem / 16) solid var(--color-text-default);
    position: relative;
    margin-right: calc(12rem / 16);
}
.has-error .radio-bar__content__box {
    border-color: var(--color-danger)
}
.radio-bar__content__box:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: calc(6rem / 16);
    height: calc(6rem / 16);
    border-radius: 50%;
    background: currentColor;
    visibility: hidden;
}
.radio-bar__input:checked ~ div > .radio-bar__content .radio-bar__content__box:before {
    visibility: visible;
}
.radio-bar__content {
    transition: background 200ms ease-in-out;
    min-height: calc(40rem / 16);
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 0 calc(10rem / 16);

    @media screen and (min-width: 768px) {
        min-height: calc(50rem / 16);
    }
}
.radio-bar:hover .radio-bar__content,
.radio-bar__input:checked ~ div > .radio-bar__content {
    background: var(--color-lightgrey);
}
.radio-bar__content__additional {
    display: none;
}
.radio-bar__input:checked ~ div > .radio-bar__content .radio-bar__content__additional {
    display: block;
}
.radio-bar__badge {
    padding: calc(8rem / 16) calc(15rem / 16);
    transform: skew(-20deg) translateX(calc(-10rem / 16));
    min-height: 100%;

    @media screen and (min-width: 768px) {
        padding: calc(12rem / 16) calc(22rem / 16);
    }
}
.radio-bar__badge__content {
    transform: skew(20deg);
    height: 100%;
}
.radio-bar__badge__content__icon {
    font-size: calc(22rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(24rem / 16);
    }
}
.radio-bar__additional {
    padding: calc(14rem / 16) calc(10rem / 16);
}
.radio-bar__additional__icon {
    margin-right: calc(10rem / 16);
}