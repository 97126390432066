.comparisonList__btn.is-active .icon:before{
    content: var(--icon-list-checked);
}
.comparisonList-counter__wrapper {
    position:relative;
    width: calc(44rem/16);
    margin: 0 auto;
    display: inline-flex;
}
.comparisonList-counter__counter {
    position:absolute;
    width: calc(27rem/16);
    height: calc(20rem/16);
    color:#fff;
    bottom:0;
    right:0;
    overflow:hidden;
    font-size: calc(12rem/16);
    font-family: var(--font-default-bold);
    padding-right: calc(7rem/16);
    display:flex;
    align-items:center;
    justify-content:center;
    z-index: 1;
    top: calc(7rem/16);
}
.comparisonList-counter__counter:before {
    content:'';
    display:block;
    width:100%;
    height:100%;
    position:absolute;
    background-color: var(--color-secondary);
    transform: skew(-20deg);
    top:0;
    left: -20%;
    z-index: -1;
}
.comparisonList-counter__icon {
    font-size: calc(23rem/16);
}
.comparisonList-overview-btn--moreProductsTabs {
    @media (min-width: 768px) {
        margin-right: calc(10rem/16);
    }
}