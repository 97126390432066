.preview-list {
    max-height: calc(400rem/16);
    padding-bottom: calc(30rem/16);
    position:relative;
    overflow-y: auto;
    overflow-x: hidden;
}
.preview-list__wrapper {
    position:relative;
}
.preview-list__wrapper:before {
    content:'';
    position:absolute;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
    height: calc(54rem/16);
    width: 100%;
    display:block;
    z-index: 2;
    bottom: 0;
}
.preview-list__header {
    padding: calc(17rem/16) calc(4rem/16);
    border-bottom: 1px solid var(--color-middlegrey);
}
.preview-list__item {
    padding: calc(17rem/16) calc(4rem/16);
    border-bottom: 1px solid var(--color-middlegrey);
    @media screen and (max-width: 767px) {
        padding: calc(10rem/16);
        line-height: calc(22/16);
    }
}
.preview-list__item--has-error {
    background-color: var(--color-lightgrey);
}
.preview-list__item__inner {
    display:flex;
    align-items:center;
    @media screen and (max-width: 767px) {
        justify-content: space-between;
    }
}