.open-order-items-grid__header{
    font-size:.875rem;
    border-top: 1px solid var(--color-middlegrey);
    font-family: var(--font-default-bold);
    background-color:var(--color-lightgrey);
}

.open-order-items-grid__header .open-order-items-grid__col{
    padding: calc(15rem/16) calc(10rem/16);
}

.open-order-items-grid__row  {
    @media screen and (min-width: 768px){
        display: grid;
        grid-template-columns: 10% 10% 8% 9% 17% 8% 5% 10% 17% 6%;
        -ms-grid-columns:  10% 10% 8% 9% 17% 8% 5% 10% 17% 6%;
    }

    border-bottom: 1px solid #B3B3B3;

    @media screen and (max-width: 767px) {
        padding: calc(10rem/16) 0;
    }
}
.open-order-items-grid__row  > .open-order-items-grid__col:nth-child(1) {
    -ms-grid-column: 1;
}
.open-order-items-grid__row  > .open-order-items-grid__col:nth-child(2) {
    -ms-grid-column: 2;
}
.open-order-items-grid__row  > .open-order-items-grid__col:nth-child(3) {
    -ms-grid-column: 3;
}
.open-order-items-grid__row  > .open-order-items-grid__col:nth-child(4) {
    -ms-grid-column: 4;
}
.open-order-items-grid__row  > .open-order-items-grid__col:nth-child(5) {
    -ms-grid-column: 5;
}
.open-order-items-grid__row  > .open-order-items-grid__col:nth-child(6) {
    -ms-grid-column: 6;
}
.open-order-items-grid__row  > .open-order-items-grid__col:nth-child(7) {
    -ms-grid-column: 7;
}
.open-order-items-grid__row  > .open-order-items-grid__col:nth-child(8) {
    -ms-grid-column: 8;
}
.open-order-items-grid__row  > .open-order-items-grid__col:nth-child(9) {
    -ms-grid-column: 9;
}
.open-order-items-grid__row  > .open-order-items-grid__col:nth-child(10) {
    -ms-grid-column: 10;
}
.open-order-items-grid__row  > .open-order-items-grid__col:nth-child(11) {
    -ms-grid-column: 11;
}

.open-order-items-grid__body .open-order-items-grid__row:hover{
    background-color: var(--color-lightgrey);
}

.open-order-items-grid__col{
    padding: calc(21rem/16) calc(10rem/16);
    display: flex;

    @media screen and (min-width: 768px){
        justify-content: center;
        flex-direction: column;
    }

    @media screen and (max-width: 767px){
        padding: 0.125rem 0.5rem;
        justify-content: space-between;
    }
}

.open-order-items-grid__btn{
    width: calc(35rem/16);
    height: calc(35rem/16);
    text-align: center;
    font-size: calc(20rem/16);
}