.product-detail-logo {
    width: calc(80rem/16);
    @media screen and (max-width: 767px) {
        width: calc(55rem/16);
    }
}

.technical-tooltip-button {
    position: absolute;
    right: 1rem;
    border:0;
    padding:0;
    -webkit-appearance: none;
    display:inline-block;
    width: 1.25rem;
    height: 1.25rem;
    line-height: 1.25rem;
    background-color: var(--color-primary);
    color:#fff;
    border-radius: 50%;
    text-align: center;
    font-size:.8125rem;
    font-family: serif;
    font-weight: bold;
    font-style: italic;
    cursor: pointer;
    margin-left: .75rem;
}
.technical-tooltip {
    padding:.5rem;
    font-size:calc(14rem / 16);
    text-align: left;
}
.technical-tooltip__img {
    display: block;
    width: auto;
    max-width:100%;
    height:auto;
    max-height:20rem;
    margin:.25rem 0 .5rem 0;
}
