.navbar__search-form {
    width: calc(600rem/16);
    height: calc(50rem/16);
    padding-left: calc(52rem/16);
    z-index: 999;

    @media screen and (max-width: 1199px) {
        width: calc(400rem/16);
    }
    @media screen and (max-width: 767px) {
        width: 100%;
        padding-left:0;
    }
}
.navbar__search-form .navbar__search-btn .icon {
    @media screen and (max-width: 767px) {
        padding-right: calc(10rem/16);
    }
}
.navbar__search {
    background-color:#fff;
    border: 1px solid var(--color-darkgrey);
    height: calc(50rem/16);
    @media (min-width: 768px) {
        border-left: none;
        margin-left: 10px;
        margin-right: -10px;
    }
}
.navbar__search-input {
    background-color:transparent;
    box-shadow:none;
    outline:none;
    border:none;
    padding-left: calc(20rem/16);

    /* set height for inline-layout */
    height: calc(50rem/16);
    @media screen and (min-width: 768px) {
        padding-left: calc(10rem/16);
    }
}

@media screen and (min-width: 768px) {
    .navbar__search:before {
        content: '';
        position: absolute;
        background: white;
        width: 30px;
        top:0;
        bottom: 0;
        transform: skew(-20deg);
        left: 0;
        border: 1px solid var(--color-darkgrey);
        z-index: -1;
    }
}

input.navbar__search-input::-ms-clear,
input.navbar__search-input::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}
.navbar__search-input:focus {
    background-color:transparent;
    box-shadow:none;
}
.navbar__search-remove {
    position:absolute;
    right:calc(20rem/16);
    line-height:1;
    top: 0;
    bottom: 0;
    padding: 0 .5rem;
    color: var(--color-middlegrey);
    font-size: calc(10rem/16);
}
.navbar__search-scan {
    position:absolute;
    right:calc(20rem/16);
    line-height:1;
    top: 0;
    bottom: 0;
    padding: 0 .5rem;
    color: var(--color-middlegrey);
}
.navbar__search-btn {
    height: calc(50rem/16);
    color:#fff;
    width: calc(70rem/16);
    display:flex;
    justify-content:center;
    align-items:center;
    font-size: calc(20rem/16);
    position:relative;
    @media screen and (max-width: 767px) {
        margin-left: calc(-10rem/16);
    }
}
.navbar__search-btn .icon {
    @media screen and (max-width: 767px) {
        padding-left: calc(5rem/16);
    }
}
.navbar__search-btn:before {
    content:'';
    display:block;
    height:100%;
    width: 100%;
    position:absolute;
    background-color: var(--color-secondary);
    transform: skew(-20deg);
    top:0;
    left: 0;
}

.navbar__search-form .navbar__search-btn:before {
    @media screen and (max-width: 767px) {
        width: auto;
        right: calc(-10rem / 16);
    }
}