.pickup-list-grid  {
    display: grid;
    grid-template-columns: minmax(90px,9%) minmax(200px,25%) 1fr 1fr 1fr 1fr 3%;
    -ms-grid-columns: minmax(90px,9%) minmax(200px,25%) 1fr 1fr 1fr 1fr 3%;
    @media screen and (max-width: 767px) {
        grid-template-columns: 50% 50%;
    }
}
.pickup-list-grid__header {
    position: relative;
    font-size:.875rem;
    border-top: 1px solid var(--color-middlegrey);
    border-bottom: 1px solid var(--color-middlegrey);
    font-family: var(--font-default-bold);
    background-color:var(--color-lightgrey);
}
.pickup-list-grid__col,
.pickup-list-grid-error__col {
    padding-left: calc(10rem/16);
    padding-right: calc(10rem/16);
    @media screen and (max-width: 1199px) {
        padding-left: calc(5rem/16);
        padding-right: calc(5rem/16);
    }
}

.pickup-list-grid-item {
    position: relative;
    border-bottom: 1px solid #B3B3B3;
    @media screen and (max-width: 767px) {
        padding-top: calc(15rem/16);
        padding-bottom: calc(15rem/16);
    }
}
.pickup-list-grid-item__inner {
    padding-top: calc(10rem/16);
    padding-bottom: calc(10rem/16);
}

.pickup-list-grid-item:hover {
    background-color:var(--color-lightgrey);
}
.pickup-list-grid-item-grid__header {
    border-bottom: 1px solid var(--color-middlegrey);
}
.pickup-list-grid-item__error {
    font-size: calc(12rem/16);
    color: var(--color-danger);
    padding: calc(5rem/16) 0;
    text-align:right;
    max-width: calc(420rem/16);
    @media screen and (max-width: 767px) {
        text-align:left;
    }
}
.pickup-list-grid-item__update-status {
    position:relative;
}

.pickup-list-grid-item input[type=number]::-webkit-inner-spin-button,
.pickup-list-grid-item input[type=number]::-webkit-outer-spin-button
{
    -webkit-appearance: none;
    margin: 0;
}
.pickup-list-grid-item__update-status.status-error .form-control {
    border-bottom: 2px solid var(--color-danger);
}

/*
.pickup-list__new-item-loading {
    position: relative;
    height: calc(70rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
}
*/

.pickup-list-grid__update-loading {
    position: absolute;
    right: 0;
}

.pickup-list-grid__col--delete {
    @media (max-width: 767px) {
        grid-column: 2;
        grid-row: 1;
        justify-self: end;
    }
}

.pickup-list-grid  > .pickup-list-grid__col:nth-child(1) {
    -ms-grid-column: 1;
}
.pickup-list-grid  > .pickup-list-grid__col:nth-child(2){
    -ms-grid-column: 2;
}
.pickup-list-grid  > .pickup-list-grid__col:nth-child(3) {
    -ms-grid-column: 3;
}
.pickup-list-grid  > .pickup-list-grid__col:nth-child(4) {
    -ms-grid-column: 4;
}
.pickup-list-grid  > .pickup-list-grid__col:nth-child(5) {
    -ms-grid-column: 5;
}
.pickup-list-grid  > .pickup-list-grid__col:nth-child(6)  {
    -ms-grid-column: 6;
}
.pickup-list-grid  > .pickup-list-grid__col:nth-child(7) {
    -ms-grid-column: 7;
}