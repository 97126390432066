.recently-viewed-teaser {
    padding: calc(15rem / 16) calc(25rem / 16);
    position: relative;

    /* flex is needed to make all items the same height */
    display: flex !important;
    flex-direction: column;

    @media screen and (min-width: 768px) {
        padding: calc(15rem / 16);
    }
}

.recently-viewed-teaser__img {
    padding: calc(15rem / 16) 0;
    margin: calc(10rem / 16) calc(-15rem / 16) calc(14rem / 16);
    position: relative;
    height: calc(130rem / 16);
}
.recently-viewed-teaser__img img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
}

.recently-viewed-teaser__bottom {
    margin-top: auto;
}