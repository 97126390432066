.cart-item-grid  {
    display: grid;
    grid-template-columns: minmax(80px,7%) 6% minmax(140px,19%) minmax(80px,7%) minmax(55px,6%) minmax(50px,6%) 15% 10% 19% 3%;
    -ms-grid-columns: 7% 6% 19% 8% 8% 8% 15% 10% 14% 3%;
    /*-ms-grid-columns: minmax(80px, 7%) 6% minmax(150px, 20%) minmax(90px, 8%) minmax(50px, 4%) minmax(50px, 6%) 15% 10% 19% 3%;*/
    @media screen and (max-width: 767px) {
        grid-template-columns: 35% 65%;
    }
}
.cart-item-grid > .cart-item-grid__col:nth-child(1) {
    -ms-grid-column: 1;
}
.cart-item-grid > .cart-item-grid__col:nth-child(2) {
    -ms-grid-column: 2;
}
.cart-item-grid > .cart-item-grid__col:nth-child(3) {
    -ms-grid-column: 3;
}
.cart-item-grid > .cart-item-grid__col:nth-child(4) {
    -ms-grid-column: 4;
}
.cart-item-grid > .cart-item-grid__col:nth-child(5) {
    -ms-grid-column: 5;
}
.cart-item-grid > .cart-item-grid__col:nth-child(6) {
    -ms-grid-column: 6;
}
.cart-item-grid > .cart-item-grid__col:nth-child(7) {
    -ms-grid-column: 7;
}
.cart-item-grid > .cart-item-grid__col:nth-child(8) {
    -ms-grid-column: 8;
}
.cart-item-grid > .cart-item-grid__col:nth-child(9) {
    -ms-grid-column: 9;
}
.cart-item-grid > .cart-item-grid__col:nth-child(10) {
    -ms-grid-column: 10;
}
.cart-item-grid > .cart-item-grid__col:nth-child(11) {
    -ms-grid-column: 11;
}
.cart-item-grid > .cart-item-grid__col:nth-child(12) {
    -ms-grid-column: 12;
}
.cart-item-grid > .cart-item-grid__col:nth-child(13) {
    -ms-grid-column: 13;
}
.cart-item-grid > .cart-item-grid__col:nth-child(14) {
    -ms-grid-column: 14;
}
.cart-item-grid > .cart-item-grid__col:nth-child(15) {
    -ms-grid-column: 15;
}
.cart-item-grid__col {
    padding-left: calc(10rem/16);
    padding-right: calc(10rem/16);
    @media screen and (max-width: 1199px) {
        padding-left: calc(5rem/16);
        padding-right: calc(5rem/16);
    }
}
.cart-item-grid__col.cart-item-grid__col--1-span7-md {
    @media screen and (min-width: 768px) {
        -ms-grid-column: 1;
        -ms-grid-column-span: 6;
        grid-column: 1 / span 6;
    }
}
.cart-item-grid__col.cart-item-grid__col--1-span7-md + .cart-item-grid__col {
    @media screen and (min-width: 768px) {
        -ms-grid-column: 7;
    }
}
.cart-item-grid > .cart-item-grid__col.cart-item-grid__col--9-span2-md,
.cart-item-grid__col.cart-item-grid__col--9-span2-md {
    @media screen and (min-width: 768px) {
        -ms-grid-column: 9;
        -ms-grid-column-span: 2;
        grid-column: 9 / span 2;
    }
}
.cart-item-grid-error .cart-item-grid-error__col:first-of-type, .cart-item-grid-error .cart-item-grid-error__col:nth-of-type(3){
    grid-column: 2/span 3;
    @media screen and (max-width: 767px) {
        grid-column: 1 / span 2;
    }
}
.cart-item-grid-error .cart-item-grid-error__col:nth-of-type(2){
    grid-column: 7/ span 3;
    @media screen and (max-width: 767px) {
        grid-column: 1 / span 2;
    }
}
.cart-item-grid__col--two-cols-xs {
    @media screen and (max-width: 767px) {
        grid-column: 1 / span 2;
    }
}
.cart-grid-item__col--right-xs {
    @media screen and (max-width: 767px) {
        grid-column: 2 / -1;
    }
}
.cart-grid-item__col--left-xs {
    @media screen and (max-width: 767px) {
        grid-column: 1 / -1;
    }
}

.cart-scrollable {
    @media screen and (min-width: 768px) {
        overflow-x: auto;
    }
}
.cart-scrollable .cart-item-grid,
.cart-scrollable .cart-item {
    font-size: calc(14rem / 16);

    @media screen and (min-width: 768px) {
        min-width: calc(1160rem / 16);
    }
    @media screen and (min-width: 1200px) {
        font-size: calc(15rem / 16);
    }
}
.cart-item-grid--15 {
    grid-template-columns: minmax(80px,6%) minmax(45px,3%) minmax(150px,15%) 4.5% 4.5% 6% 4.5% 2.5% 7% minmax(80px,8%) minmax(90px, 10%) 6% 5.5% 8.5% 8%;
    -ms-grid-columns: minmax(80px,6%) minmax(45px,3%) minmax(150px,15%) 4.5% 4.5% 6% 4.5% 2.5% 7% minmax(80px,8%) minmax(90px, 10%) 6% 5.5% 8.5% 8%;
    @media screen and (max-width: 767px) {
        grid-template-columns: 35% 65%;
    }
}
.cart-item-grid--15 .cart-item-grid__col {
    padding-left: calc(5rem/16);
    padding-right: calc(5rem/16);
}

.cart-item-grid--11 {
    grid-template-columns: minmax(80px,6%) minmax(45px,3%) minmax(150px,15%) 6% 6% 6% 6% minmax(80px, 15%) 8% 8% 10% 10%;
    -ms-grid-columns: minmax(80px,6%) minmax(45px,3%) minmax(150px,15%) 6% 6% 6% 6% minmax(80px, 15%) 8% 8% 10% 10%;
    @media screen and (max-width: 767px) {
        grid-template-columns: 35% 65%;
    }
}
.cart-item-grid--11 .cart-item-grid__col {
    padding-left: calc(5rem/16);
    padding-right: calc(5rem/16);
}