/* wishlist list */
.wishlist-list__header {
    padding: 1rem .5rem;
    font-size:.875rem;
    font-family: var(--font-default-bold);
    background-color:var(--color-lightgrey);
    border-bottom: 1px solid var(--color-middlegrey);
    border-top: 1px solid var(--color-middlegrey);

    @media screen and (min-width: 768px) {
        padding: 1rem;
    }
}
.wishlist-list__item {
    padding: 1rem .5rem;
    position:relative;
    border-bottom: 1px solid var(--color-middlegrey);

    @media screen and (min-width: 768px) {
        padding: 1rem;
    }
}
.wishlist-list__item + .wishlist-list__item {
    border-top: none;
}

@media screen and (min-width: 768px) {
    .wishlist-list__item:hover {
        background-color:var(--color-lightgrey);
    }
}

/* detail list */
.wishlist-list__header--detail,
.wishlist-list__item--detail {
    padding:1rem .375rem;
}