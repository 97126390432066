.main-footer {
    background-color:var(--color-primary);
    color:#fff;
    padding: 0 calc(100rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(20rem/16) calc(15rem/16);
    }
}
.main-footer__list {
    display:flex;
    justify-content:center;
    align-items:center;
    @media screen and (max-width: 767px) {
        flex-wrap: wrap;
        margin-bottom: calc(30rem/16);
    }
}
.main-footer__list-item {
    display:inline-block;
    font-size:calc(15rem/16);
    font-family:var(--font-headline);
    padding:0 calc(10rem/16);
    margin: calc(18rem/16) 0;
    line-height:1;
    @media screen and (max-width: 767px) {
        margin: calc(4rem/16) 0;
    }
}
.main-footer__list-item + .main-footer__list-item {
    border-left:1px solid var(--color-lightgrey);
}
.main-footer__btn {
    letter-spacing: 0;
    font-size: calc(15rem/16);
    text-transform: none;
}
.main-footer__btn:hover {
    color:inherit;
}