.shelf__row {
    overflow: hidden;
}
.shelf__item-title {
    @media screen and (min-width: 768px) {
        min-width: 12rem;
    }
}
.shelf__item-content {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.shelf__item-title,
.shelf__item-content {
    padding-top: 1rem;
    padding-bottom: 1rem;
}