.product-item {
    padding-top: calc(8rem/16);
    position:relative;
    padding-bottom: calc(8rem/16);
    border-top: 1px solid var(--color-middlegrey);
    border-bottom: 1px solid var(--color-middlegrey);
}
.product-item__expiry-date {
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    margin-top: calc(10rem/16);
}
.product-item__inner--badge {
    position: relative;
}
.product-item__action-badge {
    width: calc(60rem/30);
    position:absolute;
    right: calc(0rem/16);
    z-index: 1;
    top:0;
}
.product-item__action-badge img {
    width: 100%;
}
.product-item__logo {
    max-width: calc(80rem/16);
    @media screen and (max-width: 767px) {
        max-width: calc(50rem/16);
    }
}
.product-item__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.product-item__inner--spacing {
    padding-top: 0;
    padding-bottom: 0;
}
/* product-item-wrapper is for has-anchor-offset */
.product-item + .product-item,
.product-item-wrapper + .product-item-wrapper .product-item {
    border-top: none;
}
.product-item:hover {
    background-color:var(--color-lightgrey);
}
