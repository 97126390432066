.product-item-grid  {
    display: grid;
    grid-template-columns: 15% 50% 35% 15% 50% 50%;
    -ms-grid-columns: 15% 50% 35% 15% 50% 50%;
    @media screen and (min-width: 1366px) {
        grid-template-columns: 9% minmax(170px,41%) minmax(81px, 10%) minmax(81px, 7%) 15% minmax(150px, 18%);
        -ms-grid-columns:  9% 41% 10% minmax(81px, 7%) 15% 18%;
    }
    @media screen and (max-width: 767px) {
        grid-template-columns: 35% 65%;
    }
}

@media screen and (min-width:768px) and (max-width: 1365px) {
    .product-item-grid:not(.product-item-grid--large) > .product-item-grid__col:nth-child(4) {
        grid-column-start: 1;
    }
    .product-item-grid:not(.product-item-grid--large) > .product-item-grid__col:nth-child(4),
    .product-item-grid:not(.product-item-grid--large) > .product-item-grid__col:nth-child(5),
    .product-item-grid:not(.product-item-grid--large) > .product-item-grid__col:nth-child(6) {
        margin-top:1.25rem;
    }
}
.product-item-grid > .product-item-grid__col:nth-child(1) {
    -ms-grid-column: 1;
}
.product-item-grid > .product-item-grid__col:nth-child(2) {
    -ms-grid-column: 2;
}
.product-item-grid > .product-item-grid__col:nth-child(3) {
    -ms-grid-column: 3;
}
.product-item-grid:not(.product-item-grid--large) > .product-item-grid__col:nth-child(4) {
    -ms-grid-column: 4;
    @media screen and (min-width:768px) and (max-width: 1365px) {
        -ms-grid-row:2;
        -ms-grid-column:1;
    }
}
.product-item-grid:not(.product-item-grid--large) > .product-item-grid__col:nth-child(5) {
    -ms-grid-column: 5;
    @media screen and (min-width:768px) and (max-width: 1365px) {
        -ms-grid-row:2;
        -ms-grid-column:2;
    }
}
.product-item-grid:not(.product-item-grid--large) > .product-item-grid__col:nth-child(6) {
    -ms-grid-column: 6;
    @media screen and (min-width:768px) and (max-width: 1365px) {
        -ms-grid-row:2;
        -ms-grid-column:3;
    }
}
.product-item-grid__col {
    padding-left: calc(10rem/16);
    padding-right: calc(10rem/16);
}
.product-item-grid__col:first-child {
    padding-left: 0;
}
.product-grid-item__col--right {
    grid-column: 2 / -1;
}
.product-item-grid__col--two-cols {
    grid-column: 1 / span 2;
}


/* large griditem */
.product-item-grid--large  {
    display: grid;
    grid-template-columns: 10% 40% 10% 10% 20% 10% 50% 50%;
    -ms-grid-columns: 15% 50% 35% 15% 25% 25% 25% 25%;
    @media screen and (min-width: 1366px) {
        grid-template-columns: 10% minmax(170px,32%) minmax(40px, 6%) minmax(40px, 6%)  minmax(81px, 10%) minmax(81px, 5%) 15% minmax(150px, 15%);
        -ms-grid-columns:  10% 32% 6% 6% minmax(81px, 10%) 5% 15% 15%;
    }
    @media screen and (max-width: 767px) {
        grid-template-columns: 35% 65%;
    }
}

@media screen and (min-width:768px) and (max-width: 1365px) {
    .product-item-grid--large > .product-item-grid__col:nth-child(7) {
        grid-column-start: 1;
    }
    .product-item-grid--large > .product-item-grid__col:nth-child(7),
    .product-item-grid--large > .product-item-grid__col:nth-child(8) {
        margin-top:1.25rem;
    }
}

.product-item-grid--large > .product-item-grid__col:nth-child(4) {
    -ms-grid-column: 4;
}
.product-item-grid--large > .product-item-grid__col:nth-child(5) {
    -ms-grid-column: 5;
}
.product-item-grid--large > .product-item-grid__col:nth-child(6) {
    -ms-grid-column: 6;
}
.product-item-grid--large > .product-item-grid__col:nth-child(7) {
    -ms-grid-column: 7;
    @media screen and (min-width:768px) and (max-width: 1365px) {
        -ms-grid-row:2;
        -ms-grid-column:1;
        grid-column: 1 / span 3;
    }
}
.product-item-grid--large > .product-item-grid__col:nth-child(8) {
    -ms-grid-column: 8;
    @media screen and (min-width:768px) and (max-width: 1365px) {
        -ms-grid-row:2;
        -ms-grid-column:2;
        grid-column: 4 / span 3;
    }
}
/*
.product-item-grid--large > .product-item-grid__col:nth-child(9) {
    -ms-grid-column: 9;
    background:purple;
    @media screen and (min-width:768px) and (max-width: 1365px) {
        -ms-grid-row:2;
        -ms-grid-column:3;
    }
}*/
