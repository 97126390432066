.btn {
    text-align:center;
    letter-spacing: 1.5px;
    font-family:var(--font-default-bold);
    text-transform: uppercase;
    font-size:calc(12rem/16);
    @media screen and (max-width: 767px) {
        letter-spacing: 1.1px;
        padding: calc(9rem/16) calc(5rem/16);
    }
}
.btn-lg {
    line-height: 22px;
    @media screen and (max-width: 767px) {
        padding:calc(14rem/16) calc(15rem/16);
    }
}
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
}
.btn-no-styling:not(:disabled) {
    cursor: pointer;
}
.btn--has-icon {
    display:inline-flex;
    align-items:center;
}
.btn--has-icon .icon{
    font-size:calc(21rem/16);
    line-height: calc(10rem/16);
    margin-right: calc(10rem/16);
}
.btn-grey {
    background-color: var(--color-grey);
    font-family: var(--font-default-bold);
}
.btn--npa {
    line-height:1;
    padding: calc(10rem/16);
    font-size: calc(15rem/16);
    text-transform: none;
    letter-spacing: .5px;
}
.btn--skewed {
    transform: skew(-20deg);
}
.btn--skewed .btn__inner{
    transform: skew(20deg);
    display:block;
}
.btn.isDisabled,
.btn-no-styling:disabled {
    opacity: .65;
    pointer-events:none;
}
.icon-button.btn-sm {
    padding: .25rem;
    line-height: 1;
}