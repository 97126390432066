.hotspot-container {
    position: relative;
}
.hotspot {
    height: calc(35rem/16);
    width: calc(35rem/16);
    position:absolute;
    transform: translateX(-50%) translateY(-50%);
}
.is-open.hotspot {
    z-index: 1;
}
.is-open .hotspot__btn {
    z-index: 2;
}
.hotspot__btn {
    color:#fff;
    border-radius: 50%;
    font-size: calc(15rem/16);
    text-align:center;
    font-family: var(--font-default-bold);
    display:flex;
    justify-content:center;
    align-items:center;
    background-color:var(--color-primary);
    line-height: calc(35rem/16);
    width:100%;
    height:100%;
    position:relative;
}
.hotspot-detail {
    width: calc(360rem/16);
    background-color: var(--color-lightgrey);
    border-bottom: 1px solid var(--color-middlegrey);
    transform: translateX(0) translateY(-15px);
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    cursor: default;
    z-index: 1;
    box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
}
.hotspot-detail:before {
    content:'';
    display:block;
    position:absolute;
    height:calc(3rem/16);
    background-color:var(--color-primary);
    width:0;
    left:0;
    right:0;
    transition: width 0.2s ease;
    z-index: 2;
    transition-delay: 200ms;
}
.hotspot--detail-top .hotspot-detail:before {
    bottom:0;
}
.hotspot--detail-left .hotspot-detail:before {
    right:0;
    left:initial;
}
.is-open .hotspot-detail:before {
    width:100%;
    transition: width 0.2s ease;
}
.hotspot--detail-left .hotspot-detail {
    transform: translateX(-91%) translateY(-15px);
}
/*.hotspot--detail-right-0 .hotspot-detail {
    transform: translateX(0) translateY(-15px);
}*/
.hotspot--detail-top .hotspot-detail {
    bottom:0;
}
.is-open .hotspot-detail {
    visibility: visible;
    opacity: 1;
    transition: width 0.2s ease;
}
.hotspot-detail__content {
    padding: calc(17rem/16) calc(18rem/16);
}
.hotspot-detail__title {
    font-family: var(--font-default-bold);
}
.hotspot__btn-text--open {
    visibility:hidden;
    opacity:0;
    display:none;
    transition: opacity 0.2s ease;
}
.hotspot__btn-text {
    transition: opacity 0.2s ease;
}
.is-open .hotspot__btn-text--open {
    visibility: visible;
    display:flex;
    justify-content:center;
    align-items:center;
    opacity:1;
}
.is-open .hotspot__btn-text {
    visibility:hidden;
    opacity:0;
    display:none;
}
.hotspot-product-logo {
    width: calc(50rem/16);
}
.hotspot-product-img__wrapper {
    margin:0 auto;
    width: calc(95rem/16);
}