.navbar-meta {
    height: calc(78rem/16);
    background-color: var(--color-lightgrey);
    background-image: url("/static/img/pattern/desktop/navigation.svg");
    background-repeat:repeat-x;
    padding:0;
    z-index: 99;
    flex-wrap: nowrap;
    @media screen and (max-width: 767px) {
        height: calc(51rem/16);
    }
}
.navbar-meta .navbar-left, .navbar-meta .navbar-right {
    height:100%;
    display:flex;
    align-items:center;
}
.navbar-meta-right {
    padding-right: calc(30rem/16);
    @media screen and (max-width: 767px) {
        padding-right: calc(10rem/16);
    }
}
.navbar-meta-item {
    height: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: calc(5rem/16);
    padding-right: calc(4rem/16);

    @media screen and (min-width: 768px) {
        padding-left: calc(10rem/16);
        padding-right: calc(6rem/16);
        font-size: calc(13rem / 16);
    }
    @media screen and (min-width: 1200px) {
        padding-left: calc(15rem/16);
        padding-right: calc(10rem/16);
        font-size: calc(15rem / 16);
    }
}
.navbar-meta-item__link-icon {
    color: var(--color-primary);
    font-size: calc(26rem/16);
    margin-bottom: calc(2rem/16);
}
.navbar-meta-item__link-counter {
    position:absolute;
    width: calc(27rem/16);
    height: calc(20rem/16);
    color:#fff;
    bottom:0;
    right:0;
    overflow:hidden;
    font-size: calc(12rem/16);
    font-family: var(--font-default-bold);
    padding-right: calc(7rem/16);
    display:flex;
    align-items:center;
    justify-content:center;
    z-index: 1;
    @media screen and (max-width: 767px) {
        right: calc(-7rem/16);
    }
}
.navbar-meta-item__link-counter:before {
    content:'';
    display:block;
    width:100%;
    height:100%;
    position:absolute;
    background-color: var(--color-secondary);
    transform: skew(-20deg);
    top:0;
    left: -20%;
    z-index: -1;
}
.navbar-meta-item__link-icon-wrapper {
    position:relative;
    width: calc(70rem/16);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 767px) {
        width: calc(50rem/16);
    }
}
.navbar-meta-item__link-text .icon {
    font-size: calc(10rem/16);
    margin-left: calc(5rem/16);
}
.navbar-meta-item__link-text {
    @media screen and (max-width: 767px) {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0,0,0,0);
        white-space: nowrap;
        border: 0;
    }
}