.product-grid__quickfilter {
    height: calc(50rem/16);
    display: inline-flex;
    position: relative;
    padding: 5px 15px;
    margin-left: .625rem;
}
.product-grid__quickfilter:before {
    content:'';
    display:block;
    position: absolute;
    top:0;
    left: calc(1rem / 16);
    width:100%;
    height:100%;
    transform: skew(-20deg);
    background-color: var(--color-grey);
    z-index: -1;
}
.product-grid__quickfilter-text {
    display:block;
    padding-left: calc(5rem/16);
    font-size: calc(15rem/16);
}
.product-grid__quickfilter-text--additional {
    display:block;
    font-size: calc(11rem/16);
    padding-right:calc(20rem/16);
    padding-left: calc(5rem/16);
}
.product-grid__quickfilter-remove-icon {
    position:absolute;
    right:0;
    top:0;
    padding: calc(10rem/16);
    font-size: calc(10rem/16);
}
.product-grid__quickfilter-wrapper {
    display:flex;
    margin-bottom: 0;
    flex-wrap:wrap;
}
.product-grid__quickfilter-group {
    display:inline-flex;
    flex-wrap:wrap;
    margin-bottom: calc(10rem/16);
    row-gap: calc(10rem/16);
}
.product-grid__quickfilter-group .product-grid__quickfilter+.product-grid__quickfilter {
    margin-left:0;
}
.product-grid__quickfilter-group .product-grid__quickfilter:before {
    border-left: 5px solid #fff;
}
.product-grid__quickfilter-arrow {
    display: none;
    position:absolute;
    transform: skew(-20deg) translateY(-50%);
    left:100%;
    top: 50%;
    height: calc(30rem/16);
    margin-left: calc(-2rem/16);
}
.product-grid__quickfilter--parent .product-grid__quickfilter-arrow {
    display: block;
}
.product-grid__quickfilter-arrow:after {
    width: 0;
    height: 0;
    content:'';
    border-style: solid;
    border-width: calc(10rem/16) 0 calc(10rem/16) calc(11rem/16);
    position:absolute;
    margin-left: calc(-4rem/16);

    border-color: transparent transparent transparent var(--color-grey);
}
.product-grid__quickfilter-arrow:before {
    width: 0;
    height: 0;
    content:'';
    position:absolute;
    margin-left: calc(-3rem/16);
    margin-top: -4px;
    border-style: solid;
    border-width: 15.5px 0 15.5px 17px;
    border-color: transparent transparent transparent #fff;
}
@media screen and (max-width: 767px) {
    .product-grid__quickfilter-group--scrollable {
        padding-right: 15px;
        display: flex;
        flex-direction: row;
        white-space: nowrap;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
    }
}