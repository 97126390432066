.breadcrumb-item + .breadcrumb-item:before {
    content: var(--icon-arrow-right);
    display: inline-block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0);
    font-size: .5rem;
    vertical-align: .2em;
}


.breadcrumb--main{
    position: relative;
}

.breadcrumb--main .breadcrumb{
    padding-left: 20px;
}

.breadcrumb-home{
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(60rem/16);
    height: calc(50rem/16);
    position: relative;
    font-size: 21px;
}

.breadcrumb-home:hover{
    color: #fff;
}

.breadcrumb-home:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--color-secondary);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: skewX(-20deg);
}

.breadcrumb--main:before {
    content: '';
    position: absolute;
    top: 0;
    left: 69px;
    right: 0;
    height: 1px;
    background: #B3B3B3;
}

.breadcrumb--main:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 51px;
    right: 0;
    height: 1px;
    background: #B3B3B3;
}

.breadcrumb--inline {
    display: inline;
}

.breadcrumb--inline .breadcrumb-item {
    display: inline;
}