.generic-slider__arrow {
    display: block;
    position: absolute;
    top: 50%;
    left: calc(-30rem/16);
    transform: translateY(-50%);
    z-index: 1;
    font-size: calc(24rem/16);
    @media screen and (max-width: 767px) {
        left: calc(-10rem/16);
    }
    @media screen and (max-width: 1400px) and (min-width: 768px) {
        left: calc(-20rem/16);
    }
}

.generic-slider__arrow.arrow-next {
    left: auto;
    right: calc(-30rem/16);
    @media screen and (max-width: 767px) {
        right: calc(-10rem/16);
    }
    @media screen and (max-width: 1400px) and (min-width: 768px) {
        right: calc(-20rem/16);
    }
}

.generic-slider__arrow.slick-arrow.slick-disabled {
    opacity: .25;
}


.generic-slider--hide-dots .slick-dots {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}
