.product-teaser {
    display:flex;
    flex-direction:column;
    align-items:stretch;
    position:relative;
    min-width: calc(220rem/16);
    border: 1px solid var(--color-middlegrey);
}
.product-teaser__body {
    flex-grow: 1;
    display:flex;
    flex-direction:column;
}
.product-teaser__title {
    font-size: calc(20rem / 16);
    line-height: 1.3;
    font-family: var(--font-default-bold);
    margin-bottom: calc(8rem / 16);
    color: var(--color-primary);
}
.product-teaser__subtitle {
    font-size: calc(16rem / 16);
    line-height: 1.5;
    margin-bottom: calc(10rem / 16);
}
.product-teaser__expiry-date {
    margin-bottom: calc(15rem / 16);
}
.product-teaser__btn-additional-info {
    font-family: var(--font-default);
    letter-spacing: 1.6px;
    padding-left: calc(7rem / 16);
}
.product-teaser__body {
    position: relative;
    padding: calc(20rem / 16);
    background-color: var(--color-lightgrey);
}
.product-teaser__body:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    height: calc(80rem / 16);
    background-image: url("/static/img/pattern/mobile/pattern-dark.svg");
    background-repeat: repeat-x;
    opacity: 0.2;
    background-size: contain;
}
.product-teaser__action-badge {
    width: calc(80rem/16);
    position:absolute;
    right: calc(10rem/16);
    top:0;
    transform: translateY(-50%);
}
.product-teaser__logo-img {
    position:absolute;
    top:0;
    left:0;
    padding: calc(10rem/16);
    max-width: calc(90rem/16);
    width: 100%;
    z-index: 2;
    height: auto;
}
.product-teaser__img {
    padding: calc(20rem/16);
}