.availability-icon {
    line-height: 1;
}

.availability-icon__icon {
    width: calc(20rem/16);
    height: calc(23rem/16);
}

.availability-icon__text {
    font-size: calc(12rem/16);
    margin-top: calc(5rem/16)
}

.availability-icon--neutral .availability-icon__light {
    fill: transparent;
}

.availability-icon--success .availability-icon__light {
    fill: var(--color-success);
}

.availability-icon--danger .availability-icon__light {
    fill: var(--color-danger);
}

.availability-icon--warning .availability-icon__light {
    fill: var(--color-warning);
}