.link-group-skewed__item {
    height: calc(50rem/16);
    display: block;
    position: relative;
    padding: 5px 25px;
    margin-left: .625rem;
    @media screen and (max-width: 767px) {
       display:inline;
    }
}
.link-group-skewed__item:before {
    content:'';
    display:block;
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    transform: skew(-20deg);
    background-color: var(--color-grey);
    z-index: -1;
}
.link-group-skewed__item.active:before {
    background-color: var(--color-secondary);
}
.link-group-skewed__item.active {
    color:#fff;
}
.link-group-skewed__item-text {
    display:block;
    padding-left: calc(5rem/16);
    font-size: calc(15rem/16);
}
.link-group-skewed__item-text--additional {
    display:block;
    font-size: calc(11rem/16);
    padding-right:calc(20rem/16);
    padding-left: calc(5rem/16);
}
.link-group-skewed {
    display:inline-flex;
    flex-wrap:wrap;
    margin-bottom: calc(10rem/16);
    z-index: 2;
    position:relative;
    @media screen and (max-width: 767px) {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        flex-wrap: inherit;
        display:flex;
    }
}
.link-group-skewed .link-group-skewed__item+.link-group-skewed__item {
    margin-left:0;
}
.link-group-skewed .link-group-skewed__item:not(:first-of-type):before {
    border-left: 5px solid #fff;
}
.link-group-skewed__item-arrow {
    position:absolute;
    transform: skew(-20deg) translateY(-50%);
    left:100%;
    top: 50%;
    height: calc(30rem/16);
    margin-left: calc(-2rem/16);
}
.link-group-skewed__item-arrow:after {
    width: 0;
    height: 0;
    content:'';
    border-style: solid;
    border-width: calc(10rem/16) 0 calc(10rem/16) calc(11rem/16);
    position:absolute;
    margin-left: calc(-4rem/16);
    border-color: transparent transparent transparent var(--color-grey);
}
.link-group-skewed__item.active .link-group-skewed__item-arrow:after {
    border-color: transparent transparent transparent var(--color-secondary);
}
.link-group-skewed__item-arrow:before {
    width: 0;
    height: 0;
    content:'';
    position:absolute;
    margin-left: calc(-3rem/16);
    margin-top: -4px;
    border-style: solid;
    border-width: 15.5px 0 15.5px 17px;
    border-color: transparent transparent transparent #fff;
}
.link-group-skewed--lg .link-group-skewed__item {
    @media screen and (min-width: 768px) {
        min-width: calc(150rem/16);
    }
}